import React from 'react'
import { Link } from 'gatsby'

const TopNavBar = (props) => (
    <div style={{float:"left"}}>
        <Link to="/">
            Back to Main
        </Link>
    </div>
)

export default TopNavBar
