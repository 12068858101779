import React from 'react'
import TopNavBar from '../components/TopNavBar.js'

const HeaderGeneric = (props) => (
    <div>
        <TopNavBar/>
        <header id="header">
            <h1>{props.title}</h1>
            <p>{props.subtitle}</p>
        </header>
    </div>
)

HeaderGeneric.defaultProps = {
    title: "Generic",
    subtitle: "Ipsum dolor sit amet nullam"
}

export default HeaderGeneric
