import React from 'react'
import Helmet from 'react-helmet'

import HeaderGeneric from '../components/HeaderGeneric'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="404: Not Found" />
    <HeaderGeneric title="The page you're looking for doesn't exist." subtitle="" />
    <center>
      <p>
        Tough luck. If you got here by keying in a URL, please verify that you keyed it in correctly
        (as opposed to incorrectly). 
        <br />
        <a href="/">
          Alternatively, you can click here to return to the main page.
        </a>
      </p>
    </center>
  </Layout>
)

export default NotFoundPage
